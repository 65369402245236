import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RepeatIcon from '@mui/icons-material/Repeat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const NavBar = ({ handleMenuToggle, menuOpen }) => {
  const location = useLocation(); // useLocation inside the NavBar component

  // Determine if a route is active, return highlighted color if true
  const isActive = (path) => location.pathname === path ? "2px" : "0px";

  return (
    <AppBar position="relative" sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Navigation Buttons with Icons */}
          <IconButton component={Link} to="/main"
            sx={{
              position: 'relative',
              width: '40px', // Adjust width and height as needed
              height: '40px',
              color: location.pathname === '/main' ? '#000' : '#999', // Black when active, grey when inactive
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: location.pathname === '/main' ? '2px' : '0px', // Conditional underline
                backgroundColor: '#FEB92B',
              },
            }}
          >
            <ReceiptIcon />
          </IconButton>
          <IconButton component={Link} to="/recurrences"
            sx={{
              position: 'relative',
              width: '40px', // Adjust width and height as needed
              height: '40px',
              color: location.pathname === '/recurrences' ? '#000' : '#999', // Black when active, grey when inactive
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: location.pathname === '/recurrences' ? '2px' : '0px', // Conditional underline
                backgroundColor: '#FEB92B',
              },
            }}
          >
            <RepeatIcon />
          </IconButton>
        </Box>

        {/* Simplified User Icon with Dropdown */}
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleMenuToggle}>
          <AccountCircleIcon sx={{ color: '#000', width: "30px", height: "30px" }} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
