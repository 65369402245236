import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Divider } from '@mui/material';
import axios from 'axios';
import './recurrences.css'; // You can style this file as needed

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

const Recurrences = () => {
  const [recurrences, setRecurrences] = useState([]);
  const [loading, setLoading] = useState(true);

  const isWithinNextWeek = (date) => {
    const currentDate = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(currentDate.getDate() + 7);
    return new Date(date) >= currentDate && new Date(date) <= nextWeek;
  };

  const fetchRecurrences = async () => {
    try {
      const response = await axiosInstance.get('/recurrences');
      setRecurrences(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching recurrences:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecurrences();
  }, []);

  const renderRecurrenceRows = (recurrenceList) => {
    return recurrenceList.map((recurrence, index) => (
    <Box display={"flex"} key={index}>
        <Box
            key={index}
            sx={{
            backgroundColor: recurrence.late ? '#ffe6e6' : '#f9f9f9',
            paddingX: '15px',
            paddingY: '5px',
            borderRadius: 1,
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">{recurrence.name}</Typography>
            <Typography variant="body2">{recurrence.recurrencePattern} payment</Typography>
            <Typography variant="body2" color="grey">
                Due: {new Date(recurrence.nextPaymentDate).toLocaleDateString()}
            </Typography>
            {recurrence.late && (
                <Typography variant="body2" color="error">
                Payment is late
                </Typography>
            )}
            </Box>
            <Typography variant="subtitle1" fontWeight="bold">
            {recurrence.amount}
            </Typography>
        </Box>
      </Box>
    ));
  };

  const renderUpcomingPayments = () => {
    const upcomingRecurrences = recurrences.filter((recurrence) =>
      isWithinNextWeek(recurrence.nextPaymentDate)
    );

    return upcomingRecurrences.length > 0 ? (
      renderRecurrenceRows(upcomingRecurrences)
    ) : (
      <Typography color="grey">No payments due in the next week.</Typography>
    );
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#fff',
          }}
        >
          <CircularProgress sx={{ color: 'green' }} />
        </div>
      ) : (
        <Box
          className="recurrence-container"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            width: '50%',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Upcoming Payments
          </Typography>
          <Box sx={{ width: '100%' }}>{renderUpcomingPayments()}</Box>

          <Divider sx={{ marginY: '2rem', width: '100%' }} />

          <Typography variant="h4" gutterBottom>
            All Recurrences
          </Typography>
          <Box sx={{ width: '100%' }}>
            {recurrences.length > 0 ? (
              renderRecurrenceRows(recurrences)
            ) : (
              <Typography>No recurrences found.</Typography>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Recurrences;
