import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import axios from 'axios';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ExportCSV from '../components/ExportCSV';
import SearchBar from '../components/SearchBar'; // Import the SearchBar component
import './main.css';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 401) {
          console.error("Error 401: Not logged in:", error);
          handleLogout(); // Handle logout on 401
        } else {
          console.error("Server responded with an error:", error);
        }
      } else if (error.request) {
        console.error("No response received from server:", error.request);
      } else {
        console.error("Error in setting up request:", error.message);
      }
    } else {
      // Non-Axios errors
      console.error("Unexpected error:", error);
    }
    return Promise.reject(error);
  }
);

const handleLogout = async () => {
  try {
    // Call the backend to revoke tokens
    await axiosInstance.get('/auth/revoke', {
      maxRedirects: 0,
    });

    // Refresh the page to clear app state and detect logged-out status
    window.location.reload();
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

const Main = (props) => {
  const [syncing, setSyncing] = useState(false);
  const [daysFromSync, setDaysFromSync] = useState(90);
  const [databaseRetrieval, setDatabaseRetrieval] = useState(true);
  const [user, setUser] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  const handleSync = async () => {
    setSyncing(true);
    try {
      const response = await axiosInstance.get('/parser/' + daysFromSync, { maxRedirects: 0 });
      if (response.data.transactions != null) {
        dbRetrieval();
      }
      setDaysFromSync(1);
      setSyncing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const dbRetrieval = async () => {
    try {
      const response = await axiosInstance.get('/transactions', { maxRedirects: 0 });
      setUser(props.userData.email);

      if (response.data.transactions != null) {
        const sortedTransactions = response.data.transactions.sort(
          (a, b) => b.depositDate - a.depositDate
        );
        setTransactions(sortedTransactions);
      }
      setDatabaseRetrieval(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    setDatabaseRetrieval(true);
    dbRetrieval();

    if (props.userData.lastSync !== 0) {
      const lastSyncDate = new Date(props.userData.lastSync * 1000);
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - lastSyncDate.getTime());
      const daysFromSync = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setDaysFromSync(Math.min(daysFromSync, 90));
    } else {
      setDaysFromSync(90);
    }
  }, [location]);

  const groupTransactionsByDay = () => {
    const groups = {};
    transactions.forEach((transaction) => {
      const date = new Date(transaction.depositDate * 1000);
      const day = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      if (!groups[day]) {
        groups[day] = [];
      }
      groups[day].push(transaction);
    });
    return groups;
  };

  const renderTransactionRows = () => {
    const groupedTransactions = groupTransactionsByDay();
    const filteredTransactions = Object.keys(groupedTransactions).reduce((acc, day) => {
      const filteredGroup = groupedTransactions[day].filter((transaction) => {
        const searchLower = searchTerm.toLowerCase();
        return (
          transaction.name.toLowerCase().includes(searchLower) ||
          transaction.amount.toString().includes(searchLower) ||
          transaction.message.toLowerCase().includes(searchLower)
        );
      });

      if (filteredGroup.length > 0) {
        acc[day] = filteredGroup;
      }

      return acc;
    }, {});

    return Object.keys(filteredTransactions).map((day) => (
      <Box key={day} sx={{ marginBottom: '1rem' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '0.875rem', marginBottom: '0.5rem' }}>
          {day}
        </Typography>
        {filteredTransactions[day].map((transaction) => (
          <Box display={"flex"} key={transaction.transactionId}>
            <Box
              sx={{
                backgroundColor: '#f9f9f9',
                paddingX: '15px',
                paddingY: '5px',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
                {transaction.type === 'received' ? (
                  <ArrowDownwardIcon color="success" />
                ) : (
                  <ArrowUpwardIcon color="error" />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                  <Typography variant="subtitle1" ml={1}>
                    {transaction.name}
                  </Typography>
                  <Typography variant="subtitle2" color={"grey"} ml={1}>
                    {transaction.message}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {transaction.amount}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    ));
  };

  return (
    <div>
      {databaseRetrieval ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#fff',
          }}
        >
          <CircularProgress sx={{ color: 'green' }} />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1rem',
              borderBottom: '1px solid #ccc',
            }}
          >
            <h1 style={{ margin: 0 }}>My Transactions</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
              <button
                onClick={handleSync}
                style={{
                  border: '1px solid #ccc',
                  background: '#f7f7f7',
                  color: '#666',
                  padding: '0.5rem 1rem',
                  borderRadius: '0.25rem',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  marginRight: '1rem',
                }}
              >
                {syncing ? 'Syncing…' : `Sync last ${daysFromSync} days`}
              </button>
              <ExportCSV data={transactions} filename="transactions.csv" />
            </div>
          </div>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box className="transactions-container">
              {/* Render SearchBar component */}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              </Box>
              {transactions.length > 0 ? renderTransactionRows() : <p style={{ opacity: '50%' }}>No e-transfers yet.</p>}
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
};

export default Main;
