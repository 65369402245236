export function isJwtExpiryPassedOrMissing() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'et_jwt_expiry') {
            const expiryDate = new Date(value); // Convert expiry string to Date object
            const currentDate = new Date(); // Get the current date and time
            return currentDate > expiryDate; // Return true if the current date is after the expiry date
        }
    }

    return true;
}

export function removeJwtExpiryCookie() {
    document.cookie = 'et_jwt_expiry=; Path=/; Domain=localhost; Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
}
