// File: src/pages/PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> September 1, 2024</p>
      
      <p>Welcome to eTracer ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we access, use, store, and share your information when you use our services, particularly with respect to Google user data, in accordance with Google’s verification requirements.</p>
      
      <h2>1. Information We Collect</h2>
      <p><strong>Google User Data:</strong> When you log in to our app using Google OAuth, we collect specific information from your Google account, including but not limited to:
      <ul>
        <li><strong>Email Address:</strong> Used to identify and authenticate your account.</li>
        <li><strong>Access Tokens and Refresh Tokens:</strong> Used to provide you continuous access to your Google data and services without requiring you to re-authenticate.</li>
      </ul>
      </p>
      <p><strong>App Usage Data:</strong> We collect data on how you use our app, including actions you perform within the app, to help us improve the app’s functionality and user experience.</p>
      
      <h2>2. How We Use Your Information</h2>
      <p><strong>Authentication and Security:</strong> We use your Google email address and authentication tokens to verify your identity and maintain the security of your account.</p>
      <p><strong>Access to Google Data:</strong> With your explicit consent, we use Google OAuth to access your Gmail data. This data is used to parse specific emails for features such as managing transactions and e-transfer notifications.</p>
      <p><strong>Improving Our Services:</strong> App usage data helps us understand how users interact with our app, allowing us to enhance functionality and user experience.</p>
      
      <h2>3. How We Store Your Information</h2>
      <p><strong>Google User Data:</strong></p>
      <ul>
        <li><strong>Tokens:</strong> Refresh tokens are securely stored in our database to ensure continuous service without requiring frequent re-authentication. These tokens can be deleted at any time by logging out.</li>
        <li><strong>Email Data:</strong> Email data is temporarily stored only as needed to perform the intended service (e.g., parsing e-transfers) and is not retained longer than necessary.</li>
        <li><strong>Parsed Data:</strong> Parsed email data is stored only as needed to perform the intended service (e.g., transaction info such as date, recipient, amount) and is securely stored, encrypted, in our databases.</li>
      </ul>
      <p><strong>Data Security:</strong> We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration.</p>
      
      <h2>4. How We Share Your Information</h2>
      <p><strong>Third-Party Service Providers:</strong> We do not share your Google user data or any personal information with third-party service providers, except as required by law or to comply with a legal process.</p>
      <p><strong>Compliance with Legal Obligations:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
      
      <h2>5. Your Choices and Controls</h2>
      <p><strong>Data Access and Management:</strong> You can view and manage your Google account permissions at any time through your Google account settings.</p>
      <p><strong>Revoke Access:</strong> If you wish to revoke our access to your Google data, you can do so through the Google security settings or by contacting us directly. Upon revocation, we will delete any tokens and associated data stored on our servers.</p>
      <p><strong>Cookie Management:</strong> Our app uses cookies to manage sessions and enhance user experience. You can control cookies through your browser settings.</p>
      
      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our homepage and updating the “Effective Date” at the top of this page.</p>
      
      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our practices regarding your information, please contact us at voliantech@gmail.com.</p>
      
      <p>By using our app, you agree to the terms outlined in this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
