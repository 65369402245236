import React, { useEffect } from 'react';
import { Modal, Box, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './howItWorksModal.css';

const HowItWorksComponent = ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    } else {
      document.body.style.overflow = ''; // Re-enable background scrolling
    }

    // Cleanup function to reset overflow when the component unmounts or when 'open' changes
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="howitworks-container">
        <Typography variant="h6" component="h2" fontWeight="bold" gutterBottom>
          How it Works
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          eTracer uses Gmail to find and parse e-transfer emails, consolidating them for you. Make sure that you log in with the email you've setup for receiving e-transfer notifications!
          <List>
            <ListItem>
            ✅ Instantly get your last 3 months of e-transfers data when logging in
            </ListItem>
            <ListItem>
            📥 Download .CSV files of all your e-transfers for easy accounting
            </ListItem>
            <ListItem>
            ♻️ Sync new e-transfers whenever you want
            </ListItem>
          </List>
        </Typography>

        {/* FAQ Accordions */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight="bold">What data do you access and store?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="grey">
              We ONLY fetch emails that are from notify@payments.interac.ca, and nothing else. Then, we parse out the date, name, amount, message, and reference number, which is stored securely in our database to provide you with easier access to your data.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Is there an iOS app?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Here is the answer to FAQ question 2.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        {/* Add more accordions as needed */}
      </Box>
    </Modal>
  );
};

export default HowItWorksComponent;
