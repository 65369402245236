import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../e-tracer.png';
import { Link } from '@mui/material';
import GoogleLoginButton from '../components/GoogleLoginButton';
import HowItWorksModal from '../components/HowItWorksModal';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

const Login = () => {
  const [openModal, setOpenModal] = useState(false); // State to control the modal visibility

  useEffect(() => {
    if (window.location.search.includes('code=')) {
      handleCallback();
    }
  }, []);

  const handleCallback = async () => {
    const code = new URLSearchParams(window.location.search).get('code');
    const response = await axiosInstance.get(`/auth/google/callback?code=${code}`);

    if (response.status === 200) {
      window.location.href = "/main";
    }
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FEB92B',
      height: '100vh',
    }}>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img
          src={logo}
          alt="Logo"
          style={{
            width: '50%',
          }}
        />
        <h4 style={{ textAlign: 'center', padding: "20px" }}>
          Instant bookkeeping for your Interac e-transfers. Here's <a
            onClick={handleModalOpen}
            style={{ color: '#3A4860', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
          >
            how it works.
          </a>
        </h4>
        {/* "How it works" Link */}
        {/* <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
          textAlign: 'center',
        }}>
          <a
            onClick={handleModalOpen}
            style={{ color: '#3A4860', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
          >
            how it works.
          </a>
        </div> */}
      </div>
      <div style={{
        flex: 2,
      }}>
        {/* Google Login Button */}
        <GoogleLoginButton />
      </div>
      {/* Privacy Policy Link */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 'auto',
        marginBottom: '15px',
        textAlign: 'center',
      }}>
        <a href="/privacy-policy" style={{ color: '#3A4860', textDecoration: 'none' }}>
          Privacy Policy
        </a>
      </div>

      {/* How It Works Modal */}
      <HowItWorksModal open={openModal} onClose={handleModalClose} />
    </div>
  );
};

export default Login;
