import React from 'react';
import { CSVLink } from 'react-csv';

function ExportCSV({ data, filename }) {
  const formattedData = data.map((item) => ({
    ...item,
    depositDate: new Date(item.depositDate * 1000).toLocaleDateString(),
  }));

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Amount', key: 'amount' },
    { label: 'Id', key: 'transactionId' },
    { label: 'Date', key: 'depositDate'},
    { label: 'Message', key: 'message' },
  ]

  return (
    <CSVLink data={formattedData} headers={headers} filename={filename}>
      <button style={{
            border: '1px solid #ccc',
            background: '#f7f7f7',
            color: '#666',
            padding: '0.5rem 1rem',
            borderRadius: '0.25rem',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '1rem'}}>Export as CSV</button>
    </CSVLink>
  );
}

export default ExportCSV;
