import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { CircularProgress, Menu, MenuItem, Box } from '@mui/material';
import axios from 'axios';

import Login from "./pages/Login";
import Main from "./pages/Main";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Recurrences from './pages/Recurrences';
import { isJwtExpiryPassedOrMissing } from "./utils";
import NavBar from './components/NavBar';  // Import the NavBar component

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false); // Dropdown state
  const [userData, setUserData] = useState(null);  // User data from /user API

  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = async () => {
    try {
      // Call the backend to revoke tokens
      await axiosInstance.get('/auth/revoke', {
        maxRedirects: 0,
      });
  
      // Refresh the page to clear app state and detect logged-out status
      window.location.reload();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axiosInstance.get('/auth/validate');
        setIsAuthenticated(response.data.authenticated || false);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    if (isJwtExpiryPassedOrMissing()) {
      checkAuthStatus();
    } else {
      setIsAuthenticated(true);
      setLoading(false);
    }
  }, []);

  // Fetch the user data from the /user endpoint
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/user');
        setUserData(response.data);  // Save user data (email, lastSync, etc.)
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  if (loading || (isAuthenticated && !userData)) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#fff',
        }}
      >
        <CircularProgress sx={{ color: 'green' }} />
      </div>
    );
  }

  return (
    <Router>
      {isAuthenticated ? (
        <>
          {/* Use the NavBar component */}
          <NavBar handleMenuToggle={handleMenuToggle} menuOpen={menuOpen} userData={userData} />

          {menuOpen && (
            <Menu
              open={menuOpen}
              onClose={handleMenuToggle}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ mt: '45px' }}
            >
              <MenuItem disabled={true} onClick={handleMenuToggle}>{userData.email}</MenuItem>
              <MenuItem onClick={handleMenuToggle}>
                <button
                  onClick={handleLogout}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#666',
                    padding: "0px"
                  }}
                >
                  Logout
                </button>
              </MenuItem>
            </Menu>
          )}

          {/* Routes */}
          <Box>
            <Routes>
              {/* Pass userData to Main.js */}
              <Route path="/main" element={<Main userData={userData} />} />
              <Route path="/recurrences" element={<Recurrences />} />
              <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
          </Box>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

export default App;
