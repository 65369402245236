import React from 'react';
import { TextField } from '@mui/material';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
    return (
        <TextField
            variant="outlined"
            placeholder="Search transactions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
                marginY: '30px',
                width: '80%',
                borderRadius: '50px',
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#FEB92B',  // Replace this with your desired focus border color
                },
            }}
            InputProps={{
                style: {
                    borderRadius: "25px",
                }
            }}
        />
    );
};

export default SearchBar;
